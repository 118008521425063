import { Card, Col, Row } from "antd";
import React from "react";
import cat1 from "../assests/pairing_science.png";
import cat2 from "../assests/varietals.png";
import cat3 from "../assests/terroir.png";
import cat4 from "../assests/history.png";
import Link from "antd/es/typography/Link";

export default function Categories({ selectedCategory, setSelectedCategory }) {
  //  const [categories, setCategories] = useState(null);

  // useEffect(() => {
  //   const fetchAllCategories = async () => {
  //     const url =
  //       "https://api.contentstack.io/v3/taxonomies/ehss_category/terms";

  //     const headers = {
  //       api_key: "blt6f0f282d20bbe00b",
  //       authorization: "cs126192b9f72c7fcf7ec544c3",
  //     };
  //     try {
  //       const res = await axios.get(url, { headers });
  //       setCategories(res.data);
  //       console.log(res.data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchAllCategories();
  // }, []);

  return (
    <div>
      <Row gutter={16}>
        <Col lg={6} md={12} sm={12} xs={12}>
          <Link onClick={() => setSelectedCategory("pairing_science")}>
            <Card
              className="facility_wrapper mt-4"
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderColor: `${
                  selectedCategory === "pairing_science" ? "#b03953" : "#FFF"
                }`,
              }}
            >
              <div className="text-center">
                <img
                  alt="Pairing Science"
                  src={cat1}
                  className="card_img"
                  height={140}
                />
              </div>
            </Card>
          </Link>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <Link onClick={() => setSelectedCategory("varietals")}>
            <Card
              className="facility_wrapper mt-4"
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderColor: `${
                  selectedCategory === "varietals" ? "#b03953" : "#FFF"
                }`,
              }}
            >
              <div className="text-center">
                <img alt="Varietals" src={cat2} className="card_img" height={140} />
              </div>
            </Card>
          </Link>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <Link onClick={() => setSelectedCategory("terroir")}>
            <Card
              className="facility_wrapper mt-4"
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderColor: `${
                  selectedCategory === "terroir" ? "#b03953" : "#FFF"
                }`,
              }}
            >
              <div className="text-center">
                <img alt="Terroir" src={cat3} className="card_img" height={140} />
              </div>
            </Card>
          </Link>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <Link onClick={() => setSelectedCategory("history")}>
            <Card
              className="facility_wrapper mt-4"
              style={{
                backgroundColor: "#fff",
                borderWidth: 1,
                borderColor: `${
                  selectedCategory === "history" ? "#b03953" : "#FFF"
                }`,
              }}
            >
              <div className="text-center">
                <img
                  alt="History"
                  src={cat4}
                  className="card_img"
                  height={140}
                />
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
