import React, { useState } from "react";
import video from "../assests/Video.mp4";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

export default function Video() {
  //   const [showButton, setShowButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleVideoEnded = () => {
    // setShowButton(true);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const login = async () => {
    navigate("/home");
  };

  return (
    <div
      style={{
        margin: 0,
        padding: 30,
        height: "100vh",
        display: "flex",
        justifycontent: "center",
        alignitems: "center",
      }}
    >
      <video
        src={video}
        controls
        controlsList="nofullscreen"
        autoPlay
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
        onEnded={handleVideoEnded}
      />
      <MyModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onClick={login}
      />

      {/* {showButton && (
        <Button
          style={{
            backgroundColor: "#B03953",
            color: "#FFFFFF",
            position: "absolute",
            top: "4%",
            left: "92%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            fontWeight: 500,
          }}
          onClick={login}
        >
          Try me...
        </Button>
      )} */}
    </div>
  );
}
function MyModal({ visible, onClose, onClick }) {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={300}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifycontent: "center",
          alignitems: "center",
        }}
      >
        <p style={{ textAlign: "center", fontWeight: 600, fontSize: 20 }}>
          Get Started
        </p>
        <Button
          style={{
            backgroundColor: "#B03953",
            color: "#FFFFFF",
            fontWeight: 500,
          }}
          onClick={onClick}
        >
          Try me...
        </Button>
      </div>
    </Modal>
  );
}
